@import "../../scss/variables.scss";

.landingPage-wrapper {
  font-family: "DINNextLTArabic-Regular-3";
  background: #fff;
  min-height: 4000px;

  .nav-link-spy {
    font-family: "DINNextLTArabic-Medium-4";
    text-decoration: none;
    color: #767676;
    font-size: 16px;
  }
 
  .is-current {
    color: $primaryColor;
    text-decoration: none;
    font-family: "DINNextLTArabic-Medium-4";
  }

  .gradient-text {
    background: linear-gradient(269.67deg, #84dcff 0.06%, #659aff 18.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  header.header-wrapper {
    margin-top: 4rem;
    min-height: 530px;

    .logo2030Text {
      font-family: "DINNextLTArabic-Medium-4";
      color: #4F4F4F;
    }

    .text-wrapper {
      margin-top: 8rem;
    }
  }

  .blue-wrapper {
    width: 40px;
    height: 40px;
    background: #eaf1fe;
    border: 8px solid #f8fbff;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-hr {
    // background: linear-gradient(269.8deg, #43CBEE, #0053a1);
    border-radius: 20px;
    width: 94px;
    height: 6px;
    background: linear-gradient(269.8deg, #43cbee 0.19%, #3173f2 99.85%);
  }

  .hammer-wrapper {
    width: 48px;
    height: 48px;
  }

  .rightCheck-wrapsubTitle1per {
    width: 40px;
    height: 40px;
  }

  .subTitle1 {
    font-weight: 700;
    font-size: 36px;
    color: #0054a3;
  }

  .title1 {
    font-weight: 500;
    font-size: 20px;
    color: #43cbee;
  }

  .subTitle2 {
    font-weight: 700;
    font-size: 32px;
    color: #0054a3;
  }

  .subTitle3 {
    font-weight: 400;
    font-size: 18px;
    opacity: 0.5;
  }

  #whoIsLegit {
    background: linear-gradient(180deg, #e8f0ff 0%, #ffffff 13.5%);
    min-height: 800px;
    @media (max-width: 768px) {
      // min-height: 1000px;
    }
    .tabs-text-wrapper {
      margin-bottom: 5rem;
    }

    .action-button {
      background: #3173f2;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
    }

    .lazy-load-image-background {
      text-align: center;
      width: 100%;
      height: auto !important;
    }
    .panelHeight{
      min-height: 600px;
    }
  }

  #registration {
    .content-wrapper {
      text-align: center;
      padding: 8rem 0;

      p {
        width: 50%;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        color: #ffffff;
      }
    }

    .action-button {
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
        0px 8px 16px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
    }
  }

  #easyUse {
    background: #f2f5f7;
    border-radius: 16px;
    min-height: 660px;
  }

  #riskOfPapers {
    min-height: 900px;
    background: linear-gradient(
      180deg,
      rgba(49, 115, 242, 0.05) 68.66%,
      rgba(248, 251, 255, 0) 100%
    );
  }

  #contactUs {
    background: url("../../assets/images/landingPage/Ellipse.svg") 61% no-repeat;

    .content {
      a,
      span {
        color: #0054a3;
      }
    }

    .contactUsForm {
      padding: 27px 24px 24px;
      background: #ffffff;
      box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
        0px 10px 10px -5px rgba(0, 0, 0, 0.04);
      border-radius: 16px;

      .contactUsForm-wrapper {
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #b4bec8;
          opacity: 1;
          /* Firefox */
        }

        input,
        textarea,
        .country-select {
          // border: 1px solid #B4BEC8;
          border-radius: 4px;
          background: #f8fbff;
        }
      }
    }
  }

  #downloadApp {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 61.84%,
      rgba(222, 233, 255, 0.9) 100%
    );

    p {
      color: #0054a3;
    }
    .width207{
      width: 207px;
    };
  }

  .wrapperClassName {
    width: 768px;
    height: 530px;
  }

  .mb-6 {
    margin-bottom: 6px;
  }
  .mt-70 {
    margin-top: 70px;
  }
  .termsTitle {
    font-size: 32px;
    color: #0054a3;
  }
  .font-18 {
    font-size: 18px;
  }

  .networkErrorStyle {
    height: 67vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
  }
}


footer#footer {
  background: #0054a3;
  color: #ffff;
  a {
    color: white;
    text-decoration: none;
  }
  .upperArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
    width: 30px !important;
    height: 30px !important;
    background: #ffffff;
    border-radius: 500px;
  }
}

footer#footer {
  .allRightsReservedToLegit {
    text-align: start;
  }
  .padd-l {
    padding-left: 12px;
  }
  .height-70{
    height: 70px;
  }
}
@media (min-width: 800px) {
  #dashboard {
    .header-img {
      height: 474px;
    }
  }
}

@media (min-width: 760px) and (max-width: 1521px) {
  #registration {
    background-size: contain !important;
  }
}

@media (max-width: 760px) {
  #commonQuestions {
    .makeStyles-accordionSummary-16 {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 992px) {
  .landingPage-wrapper {
    header.header-wrapper {
      min-height: 760px;
      .text-wrapper {
        margin-top: 1rem;
      }
    }
    .header-img-style{
      height: 300px;
    }
  }

  #registration {
    .content-wrapper {
      p {
        font-size: 15px !important;
      }
    }

    .bg-wrapper {
      background-size: contain;
    }
  }
}

@media (max-width: 850px) {
  #registration {
    .content-wrapper {
      padding: 2rem !important;
    }

    .action-button {
      font-size: 9.5pt !important;
    }
  }
}

@media (max-width: 767px) {
  .title1,
  .subTitle1 {
    text-align: center;
  }

  hr {
    margin: 0 auto;
  }

  .order-sm-2-custom {
    order: 2;
  }

  .order-sm-1-custom {
    order: 1;
  }

  #downloadApp {
    .download-app-img-wrapper {
      text-align: center;

      img {
        height: 379px;
      }
    }
  }


}

@media (max-width: 912px) {
  #downloadApp {
    .download-app-img-wrapper {
      img {
        height: 400px;
      }
    }
  }
}
