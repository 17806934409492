html {
  scroll-behavior   : smooth;
  scroll-padding-top: 160px;
  font-size         : 14px;

  @include media-breakpoint-down(xl) {
    font-size: 14px;
  }

  @include media-breakpoint-down(md) {
    font-size: 12px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 10px;
  }
}

//new font DIN Next LT Arabic
@each $newFontFamily in $newFontFamilies {
  @each $newFontExtension in $newFontExtensions {
    @font-face {
      font-family: #{$newFontFamily};
      src        : url("../assets/fonts/new/#{$newFontFamily}.#{$newFontExtensions}");
      font-display: swap;
    }

    .#{$newFontFamily} {
      font-family: #{$newFontFamily};
    }
  }
}