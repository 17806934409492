@import "../../scss/variables";
.snackbarWrapper {
  position: absolute !important;
  top: 0px !important;
  .MuiAlert-message {
    font-size: 16px !important;
    display: flex !important;
    justify-content: space-between !important;
  }
  
  .action{
    margin-right: 40px;
    color: $primaryColor !important;
    cursor: pointer;
  }
  .error {
    .MuiPaper-root {
      background-color: $paleRed !important;
      color: $errorRed !important;
  
    }
  }
  
  .success {
    .MuiPaper-root {
      background-color: $lightGreen !important;
      color: $green !important;
    }
  }
  
  .MuiSnackbar-anchorOriginTopLeft {
    top: 75px !important;
  }
  
  .MuiIconButton-label {
    color: $grayText !important;
  }
  
  .MuiAlert-filledSuccess{
    border-right: 4px solid $green !important;
  }
  
  .MuiAlert-filledError{
    border-right: 4px solid $errorRed !important;
  }
}