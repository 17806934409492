@import "./variables";

body {
  font-family: "Roboto-Regular", Arial, Helvetica, sans-serif !important;
  min-height: 100vh;
  margin: 0;

  .cls-55 {
    stroke: $primaryColor;
  }

  & * {
    unicode-bidi: plaintext;
  }

  .dir-rtl {
    direction: rtl;
  }

  .dir-ltr {
    direction: ltr;
  }

  .f_size_10 {
    font-size: 10px !important;
  }
  .f_size_12 {
    font-size: 0.75em !important;
  }

  .f_size_14 {
    font-size: 0.875em !important;
  }

  .f_size_16 {
    font-size: 1em !important;
  }

  .f_size_18 {
    font-size: 1.125em !important;
  }

  .f_size_20 {
    font-size: 1.25em !important;
  }

  .f_size_24 {
    font-size: 1.5em !important;
  }

  .f_size_30 {
    font-size: 1.875em !important;
  }

  .f_size_48 {
    font-size: 3em !important;
  }

  // ==========responsive fonts=============
  .f_size_12_pt {
    //16
    font-size: 12pt;
  }

  .f_size_13_pt {
    //18
    font-size: 13.5pt !important;
  }

  .f_size_14_pt {
    font-size: 14.5pt;
  }

  .f_size_15_pt {
    //20
    font-size: 15pt !important;
  }

  .f_size_16_pt {
    //22
    font-size: 16.5pt;
  }

  .f_size_18_pt {
    //24
    font-size: 18pt !important;
  }

  .f_size_24_pt {
    //32
    font-size: 24pt !important;
  }

  .f_size_27_pt {
    //36
    font-size: 27pt !important;
  }

  // ==========responsive fonts=============
  .w-90 {
    width: 90%
  }

  .font8 {
    font-size: 5px;
  }

  .font14 {
    font-size: 14px !important;
  }

  .font18 {
    font-size: 18px !important;
  }

  .font24 {
    font-size: 24px !important;
  }

  .bold_font {
    font-family: "Roboto-Bold";
  }

  .font-bold {
    font-family: "DINNextLTArabic-Bold-4";
  }

  .regular_font {
    font-family: "Roboto-Regular";
  }

  .font-regular {
    font-family: "DINNextLTArabic-Regular-3";
  }

  .error {
    color: $errorRed;
    font-size: 0.75rem;
    margin-right: 14px;
    margin-left: 14px;
  }

  .error-msg {
    color: $errorRed;
  }

  .error-border {
    border: 1px solid $errorRed;
  }

  // .error-border {
  //   border-color: $errorRed !important;
  // }

  .error_size {
    font-size: 0.75rem;
  }

  .darkGrey {
    color: $gray;
  }

  .blue {
    color: $primaryColor !important;
  }

  .green {
    color: $green;
  }

  .orange {
    color: $orange;
  }

  .gray-color-new {
    color: $gray;
  }

  .grayText {
    color: $grayText;
  }

  .darkBlue {
    color: $darkBlue;
  }

  .color-black {
    color: black;
  }

  .color-gray {
    color: $gray;
  }

  .color-lightGray {
    color: $gray;
  }

  .color-primary {
    color: $primaryColor;
  }

  .border-primary {
    border: 1px solid $primaryColor;
  }

  .bg-paleBlue {
    background: $paleBlue;
  }

  .bg-blue {
    background-color: #4890d01a;
  }

  .bg-primaryColor {
    background: $primaryColor;
  }

  .color-primary {
    color: $primaryColor !important;
  }

  .color-darkBlue {
    color: $darkBlue;
  }

  .line-height-6-75 {
    line-height: 6.75;
  }

  .label-color-gray {
    color: $ashGrey;
    font-size: 0.9rem;
  }

  .border-primary-color {
    border: 1px solid $primaryColor;
  }

  .link {
    color: $errorRed;
    cursor: pointer;
    border-bottom: 1px solid $errorRed;

    &:hover {
      border-bottom: none;
    }
  }

  .input-field .MuiInputLabel-outlined {
    transform: translate(-14px, 16px) scale(1);
  }

  .input-field .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(-14px, -6px) scale(0.9);
  }

  .input-field .MuiOutlinedInput-multiline {
    padding: 0;
  }

  .input-field .MuiOutlinedInput-input {
    padding: $inputPadding;
  }

  .search-field .MuiOutlinedInput-input {
    padding: 11.5px 0px 11.5px 14px;
  }

  .input-field,
  .search-field {
    .MuiOutlinedInput-root {
      border-radius: $inputBorderRadius;
      // padding: 0;
    }
  }

  .input-field .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 35px;
  }

  .input-field .MuiOutlinedInput-notchedOutline {
    border-color: #BDBDBD;
  }

  .input-field .MuiOutlinedInput-notchedOutline legend,
  .input-field .MuiInputAdornment-positionStart,
  label {
    color: $ashGrey;
    font-size: 0.9em;
  }

  .MuiFormLabel-root {
    // transform: translate(18px, -6px) scale(0.9);
    font-size: 0.9rem;
    color: $ashGrey;
  }

  .cardStyles {
    border-radius: $inputBorderRadius;
    box-shadow: 0px 0px 7px 0px $inputGrayBorder;
  }

  .twitter-icon {
    color: #52aaec;
  }

  .facebook-icon {
    color: #4867aa;
  }

  .clipped {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }

  .selectOption {
    color: #000000de;
    display: block;
    padding: 0.5rem 0;

    &:hover {
      background-color: #0000000a;
    }
  }

  .preserveUnicode {
    unicode-bidi: plaintext;
  }

  .longList {
    max-height: 150px;
    max-width: 300px;
    gap: 1rem;
    overflow-y: auto;
  }

  .caseInfoDetails {
    width: 100%;
    background-color: white;
    border-radius: 8px 0px 0px 8px;

    @media (min-width: 500px) {
      width: calc(100% - 200px);
    }
  }

  .active {
    color: green;

    &-bg {
      background-color: green;
    }
  }

  .inactive {
    color: yellow;

    &-bg {
      background-color: yellow;
    }
  }

  .suspended {
    color: red;

    &-bg {
      background-color: red;
    }
  }

  .expired {
    color: grey;

    &-bg {
      background-color: grey;
    }
  }

  .loadingText {
    display: block;
    margin: 0.5rem 0;
    text-align: center;
  }

  .wordBreak {
    word-break: break-all;
  }

  .warning {
    color: $orange;
  }

  .inner-card {
    border: 1px solid $inputGrayBorder;
    border-radius: 12px;
  }

  .customError {
    margin-top: -0.5rem;
    display: block;
  }

  .wrapper {
    background-color: $white;
    border-radius: 20px;
  }

  .bg-lightBlue {
    background: $blue;
  }

  .bg-lightRed {
    background: $lightRed;
  }

  .addNewDDLName {
    font-size: 14px;
    padding: 5px 12px;
    cursor: pointer;
    margin: 0;
    // min-height:70px;
    height: 30px;
    // max-height:70px;

    &:hover {
      background-color: #f6f6f6;
    }
  }

  .rootApp {
    background-color: #fafafb;
    overflow: hidden;
    min-height: 100vh;
    overflow-y: auto;
  }

  .text-break {
    word-break: break-word;
  }

  .eighty {
    width: 80vw;
  }

  .twenty {
    width: 20vw;
  }

  .gray-color {
    color: $ashGrey !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.form-para {
  font-size: 1.2rem;
  font-weight: 500;
  color: #777;
}

.zero {
  color: #777;
}

.up {
  color: #3dd598;
}

.down {
  color: #fc5a5a;
}

.border-gray {
  border: 1px solid $inputGrayBorder !important;
}

.paleBlack {
  color: $paleBlack !important;
}

.darkPurple {
  color: $darkPurple;
}

.white-shape {
  border-radius: 20px;
  background-color: $veryLightGray;
  height: 100%;
}

.center-container {
  justify-content: center;
  align-items: center;
  display: flex;
  vertical-align: middle;
  height: 100vh;
}

.text-right {
  text-align: right !important;
}

.w-70 {
  width: 70%;
}

.w-90 {
  width: 90%;
}

.text-White {
  color: $veryLightGray !important;
}

.line-height-1-5 {
  line-height: 1.5;
}

.line-height-2-5 {
  line-height: 2.5;
}

.grayText {
  color: $grayText ;
}

.line-height-10 {
  line-height: 10px;
}

.secondary-btn {
  border: 1px solid $primaryColor !important;
  color: $primaryColor !important;
  border-radius: 0 !important;
}

.newDesign {
  font-family: "DINNextLTArabic-Regular-3";

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  label,
  input,
  select *,
  textarea,
  button {
    font-family: "DINNextLTArabic-Regular-3";
  }

  .font-bold {
    font-family: "DINNextLTArabic-Bold-4";
  }

  .blue-color {
    color: $blue;
  }

  #editorContainer {

    .tox-tinymce,
    .tox-edit-area {
      border: 1px solid #80808070 !important;
      border-radius: 0 !important;
    }
  }

  .big-gray-heading {
    //h1
    // font-size:2.5rem;
    color: $gray;
    font-family: "DINNextLTArabic-Bold-4";
  }

  .medium-blue-heading {
    //h3
    font-family: "DINNextLTArabic-Bold-4";
    color: $primaryColor;
    font-size: 1.5rem;
  }

  .semiBlack-input-label {
    //h4
    width: 100%;
    font-family: "DINNextLTArabic-Medium-4";
    color: $darkBlue;
    font-size: 1rem;
  }

  .primary-semi-orange-btn {
    background-color: $primaryColor;
    color: $veryLightGray;
  }

  .primary-border-and-color {
    border: 1px solid $primaryColor;
    color: $primaryColor;
  }

  input,
  .MuiTextField-root .MuiOutlinedInput-root {
    border-radius: 4px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 4px;
  }

  .phone-array-container {
    .country-select {
      .MuiAutocomplete-inputRoot {
        padding-bottom: 5px !important;
        padding-top: 5px !important;
      }
    }
  }

  button {
    // border-radius: 0;
    font-family: "DINNextLTArabic-Regular-3";
  }

  .width-8 {
    width: 8% !important;
  }

  .width-12 {
    width: 12% !important;
  }

  .width-20 {
    width: 20% !important;
  }

  .width-30 {
    width: 30% !important;
  }
}

.width-100px {
  width: 100px;
}

.semiBold-lightBlue {
  font-family: "DINNextLTArabic-Medium-4";
  color: $primaryColor;
}

::placeholder {
  color: #3b3a3a !important;
  font-size: 1.1rem;
  font-weight: 400;
}

.filter-red-btn {
  display: block;
  left: 0;
  position: absolute;
  top: -10%;
  z-index: 1;
}

.form-label-style {
  color: #333333;
  font-size: 14px;
}

.label2 {
  color: $paleBlack ;
  font-size: 14px;
}

.darkGray {
  color: $darkGray
}

.new-design-white-frame {
  padding: 20px;
  background: #ffffff;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 8%);
  border-radius: 8px;
  margin-bottom: 16px;
}

.input-border {
  border: 1px solid $inputGrayBorder;
}

.paleBlue_bg {
  background-color: $paleBlue;
}

.border-white {
  border: 1px solid #fff;
}

.font-14px {
  font-size: 14px;
}

.font-20px {
  font-size: 20px;
  @media (max-width: 700px) {
    font-size: 15px;
  }
}

.marginx-10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.loader {
  color: $darkBlue;
  font-size: 1.5rem;
  border-radius: 16px;
  background-color: white;
  padding: 12px 12px 12px 16px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06),
    -3px -3px 20px rgba(0, 0, 0, 0.06);
}

.borderGray {
  border-bottom: 1px solid $paleBlue;
}

.boxShadowUnset {
  box-shadow: unset !important;
}

.font-weight500 {
  font-weight: 500;
}

.gray {
  color: $gray;
}

//Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.more {
  width: 24px;
  height: 24px;
  background: $paleBlue;
  color: $primaryColor;
  border-radius: 50%;
  padding-right: 6px;
  padding-left: 5px;
  padding-bottom: 3px;
  font-size: 14px !important;
  font-weight: normal !important;
}

.fontWeight100 {
  font-weight: 100;
}

.tabCaseInfo {
  border-right: 4px solid $primaryColor;
  color: $grayText;
  font-weight: 400;
  padding-inline-start: 20px;
}

.borderPrimary {
  border: 1px solid $primaryColor !important;

  &:disabled {
    border: 1px solid #0000001f !important;
  }
}

.borderNone {
  border: none !important;
}

.font16 {
  font-size: 16px;
}

.makeStyles-mainContentWithDrawerClose-60 {
  .drawerClosed {
    width: calc(100% - (25% + 68px + 21px));
    right: 102px;
  }

  .stepperDrawerClosed {
    width: calc(100% - (75% + 68px));
    left: 20px;
  }
}

.makeStyles-mainContentWithDrawerOpen-59 {
  .drawerOpened {
    width: calc(100% - (25% + 199px + 21px));
    right: 273px;
  }

  .stepperDrawerOpened {
    width: calc(100% - (75% + 85px));
    left: 20px;
  }
}

.border-radius-4 {
  border-radius: 4px;
}

.ContactNo {
  font-size: 20px;
  color: $darkGray;
}

.view-icon-wrapper {
  width: 44px;
  height: 44px;
  background: #eff2f8;
  border-radius: 100px;
}

.dataTitle-wrapper {
  font-size: 16px;
  color: $paleBlack;
}

.data-wrapper {
  font-size: 16px;
  color: $darkGray;
  font-weight: 600;
}

.border-left-gray {
  border-left: 1px solid $ashGrey;
}

.tabTitle {
  font-size: 20px;
  font-weight: 500;
  color: $darkBlue;
}

.marMinus {
  margin-right: -10px;
}

.fc .fc-popover {
  z-index: 1000 !important;
}

.squareBg {
  width: 35px;
  height: 35px;
  background: #eff2f8;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paddingR {
  padding-right: 10px;
}

.sessionExpirationDefault,
.sessionExpirationLogin {
  z-index: 1310 !important;
}

.status {
  border-radius: 100px;
  padding: 7px;
  background: #eff2f8;
}
.lightBlueBg{
  background-color: #eff2f8;
}
.columnBorderLeft {
  border-left: 1px solid #CED4DA;
}

;

.btnAboveTableWrapper {
  padding: 10px 16px;
  border-radius: 8px 8px 0px 0px;
  background-color: white;
}

.subTitle {
  font-size: 20px;
  color: $darkGray;
}

.mb0 {
  margin-bottom: 0px !important;
}

.disabledSection {
  pointer-events: none;
  opacity: .5;
}

.displayNone {
  display: none;
}

.positionFixed {
  position: fixed !important;
}

.marginTop60 {
  margin-top: 60px;
}

.marginTop120 {
  margin-top: 118px;
}

.marginBottom60 {
  margin-bottom: 60px;
}

.zindexOver1000 {
  z-index: 1299;
}

.grayBg {
  background-color: #F9FAFB;
}

.mb90 {
  margin-bottom: 90px;
}

.mt-10 {
  margin-top: -10px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.bg-white {
  background-color: white;
}

.borderBlueGradient {
  width: 10%;
  height: 6px;
  border-radius: 10px;
  background-image: linear-gradient(90deg, rgba(49, 115, 242, 1), rgba(67, 203, 238, 1));
}
.customHeight1{
  min-height: 500px;
}
.customHeight2{
  min-height: 950px;
}
.borderDashed {
  border-bottom: 1px $inputGrayBorder dashed;
}
.networkErrorStyle {
  height: 67vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
ol {
  list-style: arabic-indic;
}
.textDecorationNone{
  text-decoration: none;
}
.fit-content{
  width: fit-content !important;
}

