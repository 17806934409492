@import "./variables";
@import "./mixins";

.accordionSummary {
  color : $primaryColor ;
  cursor: pointer;

  .recordNum {
    padding         : 0px 9px;
    border-radius   : 50%;
    background-color: lighten($primaryColor , 50%);
  }
}

.formContainer {
  width : 50%;
  margin: auto;

  @include media-breakpoint-down(md) {
    width: 75%;
  }

  @include media-breakpoint-down(sm) {
    width: 95%;
  }
}

.formContainer-new {
  width : 60%;
  margin: 0 auto;

  @include media-breakpoint-down(md) {
    width: 85%;
  }

  @include media-breakpoint-down(sm) {
    width: 95%;
  }
}

.headerSection {
  background: #fffcfc;
  padding   : 1rem;
  box-shadow: inset 0px 0px 3px 0px $inputGrayBorder;
}

.sectionTitle {
  color    : $primaryColor ;
  font-size: 1.2em;
}

.deleteIcon {
  color: $errorRed;
}

.removeBadge {
  cursor  : pointer;
  position: absolute;
  top     : -7px;
  left    : 6px;
}