@import "./variables";

#editorContainer {
  .tox-tinymce {
    border: none;
    background-color: $palePink  1;
    padding: 1rem;
    border-radius: $inputBorderRadius;

    .tox-editor-header {
      text-align: right;
      display: flex;
      flex-direction: row-reverse;

      .tox-toolbar-overlord {
        padding-bottom: 1rem;

        & *,
        .tox-tbtn svg {
          color: #00000085;
          fill: #00000085;
          border-right: none;
          background: none;
        }
      }

      .tox-toolbar__primary,
      .tox-toolbar-overlord {
        background-color: inherit;
      }
    }

    .tox-edit-area {
      border-radius: $inputBorderRadius;
      border: 1px solid $palePink  
    }

    .tox .tox-toolbar,
    .tox .tox-toolbar__overflow,
    .tox .tox-toolbar__primary {
      background: none;
    }
  }

  &.errorClass {
    .tox-edit-area {
      border-color: $errorRed;
    }
  }
}
