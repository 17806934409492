@import "./variables";

.phone-input-container .react-tel-input {
  .form-control {
    width: 100%;
    unicode-bidi: plaintext !important;
    padding: 16.5px 14px 16.5px 58px;
    font-size: 1rem;
    border-radius: $inputBorderRadius;
    margin: auto;

    &:focus {
      border: 1px solid #000;
      box-shadow: none;
    }
  }

  .special-label {
    color: $ashGrey;
    top: -11px;
    font-size: 0.9rem;
  }
}

.rtl .phone-input-container .react-tel-input .form-control {
  font-family: "Dubai-Medium";
}
