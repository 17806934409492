@import "../../scss/mixins";
@import "../../scss/variables";

#authHeader {
  min-height           : 480px;
  background-color     : $primaryColor;
  color                : white;
  padding              : 3rem 1rem;
  background-image     : url('../../assets/images/newMainBg.png');
  background-repeat    : no-repeat;
  background-size      : cover;
  background-position-x: center;

  .mainAuthHeader {
    position: relative;
    width   : 85%;
    margin  : auto;

    #authFormContainer-newDesign {
      transform    : translate(0, 25%);
      width        : 100%;
      margin       : auto;
      padding      : 3rem 1.5rem;
      border-radius: 0;
      position     : absolute;
    }

    .MuiStep-root.MuiStep-horizontal {
      text-align          : center;
      padding-inline-start: 0;
    }

    .MuiStepper-horizontal {
      align-items: baseline;

      span:not(.MuiStepLabel-labelContainer),
      span:not(.MuiStepLabel-label) {
        padding-left: 8px;
      }

    }

    span.MuiStepLabel-labelContainer,
    .MuiStepLabel-label,
    .MuiStepLabel-horizontal {
      padding-left: 0 !important;
    }
  }

  h2 {
    font-size: 3.857em;
  }

  @include media-breakpoint-down(md) {
    min-height: 400px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 350px;
  }

}

@media(max-width:576px) {
  #authHeader {
    .mainAuthHeader {
      width: 100%;
    }

    .stepper-wrapper {
      width: 95% !important;
    }
  }
}
 
@media(max-width:376px) {
  #authHeader {
    .stepper-wrapper {
      width: 100% !important;
    }

    #authFormContainer-newDesign {
      transform: translate(0, 15%) !important;
    }
  }
}

// .MuiAppBar-positionStatic {
//   position: unset !important;
// }