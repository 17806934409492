/***************************** colors *****************************/
$primaryColor    :#3173F2;
$blue            :#5990F4; // #1d7ab4;
$darkBlue        :#001E55; // #1F2F49
$veryLightBlue   :#1D7AB41A;
$paleBlue        :#EAF1FE; // #E5EFFF
$seaGreen        :#27AE60; // #1db49d 
$lightBlue2      :#3173F2;
$green           :#219653; //#3a974c;
$lightGreen      :#e8f7f5;
$palePurple      :#BB6BD9; // #897DE8
$lightRed        :#D1342D; //#de4040
$errorRed        :#E44D47; //f44336
$paleRed         :#FFEDE9; // #F7DEE6
$paleBlue1       :#F4F9FF; // #FDF2EA
$orange          :#F2994A; // #e370291a
$yellow          :#F2C94C; // #FFB743
$darkHoney       :#d09e5c;
$gray            :#636363; //#757575
$grayText        :#828282; // #92929D;
$lightestGray    :#ADB2BE;
$lightestGray2   :#eaeaea;
$ashGrey         :#BDBDBD; // #adb2be
$inputGrayBorder :#E0E0E0; // #CDCFD3
$veryLightGray   :#F8F8F8;
$palePink        :#F2F2F2; // #e5e7eb
$mainBg          :#fafafb;
$white           :#ffffff;
$soPaleGray      :#CED4DA;
$lightBlack      :#4F575E;
$soPaleGray2     :#FCFCFC;
$paleBlack       :#4F4F4F;
$darkGray        :#333333;
$darkOrange      :#F78D2F;
$paleOrange      :#FFF3E9;
$darkPurple      :#605BFF;
$lightPurple     :#E8E8FF; 

/***************************** fonts *****************************/
// $fontExtensions: "woff";
// $fontFamilies  : "Dubai-Bold",
// "Dubai-Light",
// "Dubai-Medium",
// "Dubai-Regular",
// "Poppins-Bold",
// "Poppins-Medium",
// "Poppins-Regular",
// "Poppins-SemiBold",
// "Poppins-Thin",
// "Roboto-Black",
// "Roboto-Bold",
// "Roboto-Light",
// "Roboto-Medium",
// "Roboto-Regular";
$newFontExtensions: "ttf";
$newFontFamilies  : "DINNextLTArabic-Bold-4",
"DINNextLTArabic-Medium-4",
"DINNextLTArabic-Regular-3";

/***************************** paddings *****************************/
$inputPadding : 14.5px;

/***************************** borders *****************************/
$inputBorderRadius: 4px;

/***************************** break points *****************************/
$grid-breakpoints: (xs: 575.98px,
  sm: 767.98px,
  md: 991.98px,
  lg: 1199.98px,
  xl: 1399.98px,
);