.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.row {
  // --bs-gutter-x: 1.5rem;
  // --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.col-8 {  
  flex: 0 0 66.66667%; /* Equivalent to (8/12) * 100% */  
  max-width: 66.66667%; /* Maximum width for the column */  
}  
.col-4 {  
  flex: 0 0 33.33333%; /* Equivalent to (4/12) * 100% */  
  max-width: 33.33333%; /* Maximum width for the column */  
}  

@media (min-width: 768px) {
  .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
  }
}
@media (min-width: 768px) {
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
}

@media (min-width: 500px) {

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

.col-md-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.m-0 {
  margin: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
  /* or equivalent to 4px when root font size is 16px */
}

.mb-4 {
  margin-bottom: 1.5rem !important;
  /* or equivalent to 24px when the root font size is 16px */
}


.mb-5 {
  margin-bottom: 3rem !important;
  /* or equivalent to 48px when root font size is 16px */
}

.mt-4 {
  margin-top: 1.5rem !important;
  /* or equivalent to 24px when root font size is 16px */
}

.mt-5 {
  margin-top: 3rem !important;
  /* or equivalent to 48px when the root font size is 16px */
}

.mx-2 {
  margin-left: 0.5rem !important;
  /* 8px if the root font size is 16px */
  margin-right: 0.5rem !important;
  /* 8px if the root font size is 16px */
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.m-auto {
  margin: auto !important;
}

// .py-1 {
//     padding-top: 0.25rem !important;
//     padding-bottom: 0.25rem !important;
//   }

//   .py-2 {
//     padding-top: 0.5rem !important;
//     padding-bottom: 0.5rem !important;
//   }
.p-2 {  
  padding: 0.5rem !important; /* 8px, based on the default Bootstrap spacing scale */  
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

//   .py-4 {
//     padding-top: 1.5rem !important;
//     padding-bottom: 1.5rem !important;
//   }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.px-1 {  
  padding-left: 0.25rem; /* 4px, based on the default Bootstrap spacing scale */  
  padding-right: 0.25rem; /* 4px, based on the default Bootstrap spacing scale */  
}  
.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-4 {
  padding-left: 1.5rem!important;
    padding-right: 1.5rem!important;
}
.pt-4 {
  padding-top: 1.5rem !important;
}
.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.align-items-center {
  align-items: center !important;
}
.align-items-end {
  align-items: end !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}