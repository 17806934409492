@import "./variables";

.dropzoneContainer {
  fieldset {
    border       : 1px dashed $inputGrayBorder;
    border-radius: $inputBorderRadius;
    cursor       : pointer;
    color        : $gray;

    &.error-border {
      border-color: $errorRed;

      legend {
        color: $errorRed;
      }
    }

    legend {
      width        : auto;
      color        : $ashGrey;
      font-size    : 0.9em;
      margin-bottom: 0;
    }

    .upload-icon {
      color: $palePink
    }
  }

  .previewContainer {
    display       : flex;
    flex-direction: row;
    flex-wrap     : wrap;

    .previewCard {
      display            : flex;
      align-items        : center;
      // padding         : 7px 10px;
      padding            : 12px;
      border-radius      : 8px;
      box-shadow         : 0px 0px 4px 0px $inputGrayBorder;
      background-color   : #fcfcfc;
      // width           : 175px;
      width              : 213.79px;
      height             : 64px;

      .iconContainer {
        color: #ea9658;

        img {
          width : 31.55px;
          height: 38.63px;
        }
      }

      .closeIcon {
        cursor: pointer;
      }

      .fileName {
        width: 90px;
      }
    }
  }
}