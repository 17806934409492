.rtl {
  text-align    : right;
  direction     : rtl;
  font-family   : 'DINNextLTArabic-Regular-3';

  & * {
    unicode-bidi: isolate;
  }

  legend {
    float: inherit;
  }

  // .bold_font {
  //   font-family: "Dubai-Bold";
  // }

  // .regular_font {
  //   font-family: "Dubai-Regular";
  // }

  // .medium-font {
  //   font-family: "Dubai-Medium"
  // }

  [type="email"],
  [type="number"],
  [type="tel"],
  [type="url"] {
    direction: rtl;
  }

  .input-field .MuiOutlinedInput-root {
    input {
      unicode-bidi: plaintext;
      text-align  : right;
    }
  }
}